// 配置全局接口域名
// const baseUrl = 'https://durdana-api.hamdam.biz/';
// const apiUrl = 'https://durdana-api.hamdam.biz/api/';

// Nurdala
// const baseUrl = 'https://nurdala-api.hamdam.biz/';
// const apiUrl = 'https://nurdala-api.hamdam.biz/api/';

const baseUrl = 'https://class-api.hamdam.biz/';
const apiUrl = 'https://class-api.hamdam.biz/api/';

export default {
 baseUrl: baseUrl,
 apiUrl: apiUrl,
 imgActionUrl: apiUrl + 'common/files/image'
}